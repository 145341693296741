import React from "react"

//import { Link } from "gatsby"
import Layout from "../../components/layout"
import LogoImage from "../../components/logoimage"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import {Hero, Container, Columns, Heading, Navbar, Content} from 'react-bulma-components'
import { Link } from "gatsby";
const BlogPage = () => {
  
  return (  
      <Layout>
          <Seo title="How Mining Bitcoin Actually Helps The Environment" 
            description="The topic of climate change has made bitcoin a whipping boy in the context of the potentially harmful effects 
            of computing on the environment"
          />
          <Hero size="fullheight">
            <Hero.Header renderAs="header">
                <Container>
                <Navbar>
                <Navbar.Brand>
                    <Navbar.Item href='/'>
                    <LogoImage type="green"/>
                    </Navbar.Item>
                </Navbar.Brand>
                </Navbar>
                </Container>
            </Hero.Header>
            <Hero.Body>
            <Container>
                <Columns.Column size="two-thirds">

            <Heading>How Mining Bitcoin Actually Helps The Environment</Heading>
            <Heading subtitle size="6" renderAs="h2">August 23, 2019</Heading>
            <Content>
            The topic of climate change has made bitcoin a whipping boy in the context of the potentially harmful effects 
            of computing on the environment. 
            It is estimated that the amount of electricity that mining pools use is greater than that of some countries, 
            which capture the public's imagination. Globally, this is estimated at 1% of energy produced. 
            Consensus in a decentralized bitcoin network is based on the so-called proof of work, 
            PoW algorithm performed by a huge number of computers. 
            Mining pool owners are motivated to have the largest share of the computing power in the network and, as a result, to use more energy. 
            Over time, network power reaches record levels. It seems this process will intensify and may be a threat to the environment.
            </Content>
            <Content>
            However, the erroneous conclusion is that if it wasn't for the bitcoin network, 
            the energy needed would not be produced by various types of power plants. 
            The fact is, mines very often <strong>take advantage of the inefficiencies</strong> of 
            various producers who would otherwise have no demand for the energy produced, e.g. due to their geographical location 
            or the lack of such high demand in relation to energy supply.
            </Content>
            <Content>
            Energy storage is difficult and large amounts of energy produced is wasted, currently mainly in China. 
            For the mines, the most important is the low energy price and they naturally <strong>gravitate to places where for power producers mining 
                facilities sometimes are the only client</strong>. 
                World demand for energy is uneven and supply still exceeds demand in many places. 
                The so-called mining allows energy storage in the form of cryptocurrency and is a powerful 
                ally of decentralized energy production in the form of financial incentives, especially in 
                locations where reselling to the power grid is impossible or not very profitable.
            </Content>
            <Content>
            For example, a hydroelectric power plant in Canada (Hydro-Quebec) has an oversupply of up to 100 TWh allowing it to supply 60,000. homes for a year)
            </Content>
            <Content>
            Very soon this location became a mecca of the cryptocurrency miners and allowed to earn over USD 3 billion 
            for the hydroelectric power plant in the last four years. 
            Interestingly, they were forced to share the profit with the inhabitants of the province because the profit was higher 
            than allowed by the local energy market regulator.
            </Content>
            <Content>
            Attacking bitcoin networks for energy consumption is easy due to the ease of estimating this consumption. 
            It is estimated that Bitcoin will use 7.7GW this year. In the case of an anachronistic banking system, 
            consumption is hard to estimate and often hidden and surprisingly high. The level of utilization of the bitcoin transaction 
            system is not independent of the allocated computing power. 
            In general, criticizing the Bitcoin network for energy consumption is associated with a lack of understanding 
            of the broader perspective of the problem, especially since <strong>effective mining motivates the creation of innovations</strong> in 
            the area of cheap energy production and its efficient use.
            </Content>
            <Content>
            <Link to="/">HeatMiner</Link> is an example of energy <strong>optimization by recovering heat from energy intensive 
            electronic devices such as GPU</strong> (graphic cards) and ASICs for heating homes and buildings, 
            which in addition are now motivated to install solar panels and small wind turbines, and most importantly, 
            from a broader perspective, reduce low emissions of buildings, which are mainly heated using fossil fuels currently, 
            which result in smog in urban agglomerations. From a Bitcoin network perspective, 
            an cryptocurrency miner in every home will also increase the level of decentralization of the entire transaction network. 
            Our dream is for as many homeowners as possible to install this type of heating and at the same time be part of the 
            financial system revolution in the world. We invite you to cooperate with us.
            </Content>
            </Columns.Column>
            </Container>
        </Hero.Body>
        </Hero>

        <Footer/>
    </Layout>
  )
  
}

export default BlogPage